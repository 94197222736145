import React, {Component} from 'react'
import Autorized from './components/Autorized';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/stylesheet.css'

class App extends Component {
  
  constructor() {
    super();
    this.state = {
      
    };
  }

  render() {
    return (
      <div>
        <Autorized/>
      </div>
    );
  }
}

export default App