import React, { Component } from 'react'
import Logo from '../assets/img/CUCLogo.svg'
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { gapi } from 'gapi-script';
import 'chart.js/auto';
import { Pie, Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

class Autorized extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 'summary',
      wlapsdata: [],
      wlapsleadsdata: [],
      wlapstotaldata: [],
      wlapstarget: 50,
      data: [],
      date: [],
      wlapsdate: [],
      wlapsSelectedDate: '',
      selectedDate: '',
      labels: [],
      offers: [],
      appreceived: [],
      conversionRate: [],
      paidRegistration: [],
      acceptedPaid: [],
      target: [],
      CLIENT_ID: "159872337006-39dtuar4k41ckj77c81th4pl9bsgbb9u.apps.googleusercontent.com",
      API_KEY: "AIzaSyAkyd4FOQC4Vr54K2JBjq_1DqfoM0AqHtc",
      DISCOVERY_DOCS: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
      SCOPE: "https://www.googleapis.com/auth/spreadsheets.readonly",
      SPREADSHEET_ID: "14_w5PSOvgf2kcZ7AyUwwtt8huE5AwqmKlZmYmaYZQtY",
      SPREADSHEET_ID2: "1vh_i-D3BspJIGMc8mWHrhBRf4b5Zde7KHigq44oiS74",
      isLoggedIn: false,
      load: "preventLoad",
      loaded: "",
      sidebar: "",
      printStatus: ""
    }

    this.listData = this.listData.bind(this)
    this.listData2 = this.listData2.bind(this)
    this.handleSelection = this.handleSelection.bind(this)
    this.handleSelection2 = this.handleSelection2.bind(this)
    this.handlePageSelection = this.handlePageSelection.bind(this)
    this.handleAuthClick = this.handleAuthClick.bind(this)
    this.handleSignoutClick = this.handleSignoutClick.bind(this)
    this.getEventTarget = this.getEventTarget.bind(this)
    this.filterAction = this.filterAction.bind(this)
    this.printAction = this.printAction.bind(this)
    this.printActionKey = this.printActionKey.bind(this)
  } 

  componentDidMount(){
    this.handleClientLoad()

    setTimeout(()=>{
      this.setState({
        loaded: "loaded"
      })
    },500)

  }

  handleClientLoad = () => { //initialize the Google API
    gapi.load('client:auth2', this.initClient);
  }

  initClient = () => { //provide the authentication credentials you set up in the Google developer console
    gapi.client.init({
      'apiKey': this.state.API_KEY,
      'clientId': this.state.CLIENT_ID,
      'scope': this.state.SCOPE,
      'discoveryDocs': ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
    }).then(()=> {
      gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSignInStatus); //add a function called `updateSignInStatus` if you want to do something once a user is logged in with Google
      
      if(gapi.auth2.getAuthInstance().isSignedIn.get() === true) {
        this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get())
      }
      
    }, function(error) {
      console.log(JSON.stringify(error, null, 2));
    });
  }

  listData() {
    const _this = this
    gapi.client.sheets.spreadsheets.values.get({
      spreadsheetId: this.state.SPREADSHEET_ID,
      //range: 'Form Response 1!A1:CL',
      range: 'Data!A2:K',
    }).then(function(response) {
      const range = response.result
      //const reducer = (accumulator, curr) => accumulator + curr;

      //var targetenrolment = 0
      if (range.values.length > 0) {
        _this.setState({
            date: [...new Set(range.values.map((value) => value[10]))]
        },()=> {
          const sd = _this.state.date.length
          _this.setState({
            selectedDate: _this.state.date[sd-1],
            data: range.values
          },()=>{
            _this.updateData(_this.state.selectedDate)
          })
        })
        //console.log(range.values)
        /*const valtargetenrolment = range.values.map((value) => parseInt(value[1])).reduce(reducer)
        console.log(valtargetenrolment);
        */
      } else {
        alert('No data found.');
      }

    }, function(response) {
      console.log('Error: ' + response.result.error.message);
      console.log(response.result.error);

      if(response.result.error.message === "The caller does not have permission") {
        _this.handleSignoutClick()
      }
    });
  }

  listData2() {
    const _this = this
    gapi.client.sheets.spreadsheets.values.get({
      spreadsheetId: this.state.SPREADSHEET_ID2,
      //range: 'Form Response 1!A1:CL',
      range: 'Form responses 1!A2:I',
    }).then(function(response) {
      const range = response.result

      const newRange = range.values.map((value) => [...value,_this.dateConvert(value[0])])

      if (newRange.length > 0) {
        _this.setState({
            wlapsdate: [...new Set(newRange.map((value) => value[9]))]
        },()=> {
          const sd = _this.state.wlapsdate.length
          _this.setState({
            wlapsSelectedDate: _this.state.wlapsdate[sd-1],
            wlapsdata: newRange
          },()=>{
            _this.updateData2(_this.state.wlapsdate[0],_this.state.wlapsSelectedDate)
          })
        })
        
      } else {
        alert('No data found.');
      }

    }, function(response) {
      console.log('Error: ' + response.result.error.message);
      console.log(response.result.error);

      if(response.result.error.message === "The caller does not have permission") {
        _this.handleSignoutClick()
      }
    });
  }



  updateData(sdate) {
    const _this = this.state
    const newData = _this.data.filter((date) => date[10] === sdate)
    const labels = newData.map((value) => value[0])
    const target = newData.map((value) => value[1])
    const offers = newData.map((value) => value[4])
    const conversionRate = newData.map((value) => value[8])
    const paidRegistration = newData.map((value) => value[9])
    const appreceived = newData.map((value) => value[2])
    const acceptedPaid = newData.map((value) => value[7])
    
    this.setState({
      labels: labels,
      offers: offers,
      conversionRate: conversionRate,
      paidRegistration: paidRegistration,
      appreceived: appreceived,
      target: target,
      acceptedPaid: acceptedPaid,
    }, () => {
      //console.log(_this.state.data)
    })
  }


  updateData2(sdata,edate) {
    //this.listData2()
    const _this = this
    // Filter by Date Range
    var startDate = new Date(sdata);
    var endDate = new Date(edate);
    const rangeFilter = _this.state.wlapsdata.filter((value) => {
      var rdate = new Date(value[9])
      return rdate >= startDate  && rdate <= endDate
      })

    // Get who submitted the data
    const wlapsteam = [...new Set(rangeFilter.map((value) => value[7]))]

    const hot = rangeFilter.map((value) => parseInt(value[1])).reduce((a, b) => a + b, 0)
    const mild = rangeFilter.map((value) => parseInt(value[2])).reduce((a, b) => a + b, 0)
    const cold = rangeFilter.map((value) => parseInt(value[3])).reduce((a, b) => a + b, 0)

    const team = [[],[],[],[]]
    for (let i = 0; i < wlapsteam.length; i++) {
      const r = rangeFilter.filter((value) => value[7] === wlapsteam[i])
      const applaunch = r.map((value) => parseInt(value[4])).reduce((a, b) => a + b, 0)
      const paidreg = r.map((value) => parseInt(value[6])).reduce((a, b) => a + b, 0)

      team[0].push(this.nameConvert(wlapsteam[i]))
      team[1].push(applaunch)
      team[2].push(paidreg)
      team[3].push(_this.state.wlapstarget)
    }

    _this.setState({
      wlapsleadsdata: [['Hot','Mild','Cold'],[hot,mild,cold]],
      wlapstotaldata: team
    })
  }

  handleSelection(e){
    const {value} = e.target
    this.setState({
        selectedDate: value
    },()=>{
      this.updateData(value)
    })
  }

  handleSelection2(e){
    const {value} = e.target
    this.setState({
        wlapsSelectedDate: value
    },()=>{
      this.updateData2(this.state.wlapsdate[0],value)
    })
  }

  handlePageSelection(e){
    const {value} = e.target
    this.setState({
        page: value
    },()=>{

      if(this.state.page === "summary") {
        this.updateData(this.state.selectedDate)
      } else {
        this.updateData2(this.state.wlapsdate[0],this.state.wlapsSelectedDate)
      }
      
    })
  }

  updateSigninStatus = (isSignedIn) => {
    if (isSignedIn) {
      this.setState({
          isLoggedIn: true
      },()=>{
        this.listData()
        this.listData2()
        //console.log(isSignedIn)
        //console.log(gapi.auth2.getAuthInstance().currentUser.get().wt.Ad);
      })
    } else {
      console.log(isSignedIn)
    }
  }

  /**
  *  Sign in the user upon button click.
  */
  handleAuthClick(event) {
    gapi.auth2.getAuthInstance().signIn().then(()=>{
      if(gapi.auth2.getAuthInstance().isSignedIn.get() === true) {
        this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get())
      }
    })
  }

  /**
  *  Sign out the user upon button click.
  */
  handleSignoutClick(response) {
    //window.gapi.auth2.getAuthInstance().signOut();
    var auth2 = window.gapi.auth2.getAuthInstance();
    if (auth2 !== null) {
      auth2.signOut().then(
        auth2.disconnect().then(()=>{
          this.setState({
            isLoggedIn: false
          })
        })
      )
    }
  }
 
  getEventTarget(e) {
      e = e || window.event
      return e.target || e.srcElement 
  }

  filterAction(e) {
    e.preventDefault()
    const status = (this.state.sidebar) ? "" : "opened"
    this.setState({
      sidebar: status
    })
  }
 
  printAction(e) {
    e.preventDefault()
    this.setState({
      printStatus: "printing"
    },() => {
      window.print()
      this.setState({
        printStatus: ""
      })
    })
  }

  printActionKey(key) {
      //console.log(key)
      this.setState({
        printStatus: "printing"
      }, () => {
        setTimeout(() => {
          this.setState({
            printStatus: ""
          })
        },3000)
      })
  }

  dateConvert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  newDateConvert(str) {
    var monthNames = [ "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December" ];

    var date = new Date(Date.parse(str))
    return monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
  }

  nameConvert(str) {
    var n = str.split('@')
    var n2 = n[0].split('.')

    return this.capitalize(n2[0]) + ' ' + this.capitalize(n2[1]);
  }

  capitalize(s)
  {
      return s[0].toUpperCase() + s.slice(1);
  }

 
  render() {
    const _this = this.state
    //const date = this.dateConvert(_this.classDetails[4])
    return (
      <div className={"data-container "+ this.state.loaded}>
        <KeyboardEventHandler
          handleKeys={['ctrl+p', 'meta+p']}
          onKeyEvent={(key, e) => this.printActionKey(key) } />
        
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                    <div className="autorize-container text-center">
                      <section>
                        <div className="autorize-wrapper mt-5">
                          <img src={Logo} alt="Logo" width="300px"/>
                            <br />
                            <br />
                          {(_this.isLoggedIn === false) ?
                          <button className="btn btn-primary" onClick={this.handleAuthClick}>Login</button>
                          : 
                          <>
                          { (this.state.printStatus === '') &&
                          <>
                            
                            <div className={"btn-container " + _this.printStatus}>
                              <a href="#filter" className="open-filter btn-primary btn-fixed" onClick={this.filterAction}><i className="mdi mdi-filter"></i></a>
                              <a href="#print" className="btn-print btn-primary btn-fixed" onClick={this.printAction}><i className="mdi mdi-printer"></i></a>
                            </div>
                          </>
                          }

                          <div className={"side-container " + _this.sidebar + " " + _this.printStatus}>
                            <div>
                              <div className="container-title mt-4 mb-4"><h2 className="font-weight-bold upper">Filter By:</h2></div>
                            </div>
                            <div>
                              <div className="row-header">
                                <b>Page:</b>
                                  <select className="page-btn" name="page" onChange={this.handlePageSelection} >
                                      <option key="page-1" value="summary" selected>Summary Report</option>
                                      <option key="page-2" value="weekly">Weekly Leads, Applications, & Payments Status</option>
                                  </select>
                              </div>
                              {(this.state.page === "summary") ?
                              <div className="row-header">
                                <b>Summary Date:</b>
                                  <select className="page-btn" name="page" onChange={this.handleSelection} >
                                  {_this.date.map((date, i) => (
                                    <option key={i} value={date} selected={(date) === _this.selectedDate}>{date}</option>
                                  ))}
                                  </select>
                              </div>
                              : (this.state.page === "weekly") ?
                              <div className="row-header">
                                <b>Weekly Date:</b>
                                  <select className="page-btn" name="page" onChange={this.handleSelection2} >
                                  {_this.wlapsdate.map((date, i) => (
                                    <option key={i} value={date} selected={(date) === _this.wlapsSelectedDate}>{this.newDateConvert(date)}</option>
                                  ))}
                                  </select>
                              </div>
                              :
                              <div className="row-header">
                                <div>No Data</div>
                              </div>
                              }
                            </div>
                            <button className="btn btn-danger btn-logout" onClick={this.handleSignoutClick}>Logout</button>
                          </div>

                          <div className="containerWrapper">
                            <div className="">
                              <div className="row">
                                <div className="col-lg-12 mt-5 mb-5">
                                  <div className="report-header text-left">
                                  <p className="header text-uppercase">Information</p>

                                  {(this.state.page === "summary") ?
                                    <div className="wrapper">
                                      Report Name: <b>Summary Report</b> <br/>
                                      Report Date: <b>{this.state.selectedDate}</b> 
                                    </div>
                                  : (this.state.page === "weekly") ?
                                    <div className="wrapper">
                                      Report Name: <b>Weekly Leads, Applications, and Payment Report</b> <br/>
                                      Report Date: <b>{this.newDateConvert(this.state.wlapsSelectedDate)}</b> 
                                    </div>
                                  :
                                    <div className="wrapper">
                                      Report Name: <b>N/A</b> <br/>
                                      Report Date: <b>N/A</b> 
                                    </div>
                                  }
                                  
                                  </div>
                                </div>

                                {(this.state.page === "summary") ? 
                                <div className="summaryReport-container">
                                  <div className={this.state.printStatus + "ContainerFirst col-lg-12"}>
                                    <div className="title">Applications Received v Offers Made</div>
                                    <div className="subtitle"></div>
                                  </div>

                                  <div className={this.state.printStatus + "ContainerFirstBottom col-lg-12 mb-5"}>
                                    <Bar
                                      data={{
                                        labels: this.state.labels,
                                        datasets: [
                                          {
                                            type: 'bar',
                                            label: 'Applications Received',
                                            data: this.state.appreceived,
                                            backgroundColor: [
                                              'rgb(243, 150, 62)',
                                            ],
                                            borderColor: [
                                              'white',
                                            ],
                                            borderWidth: 2,
                                          },
                                          {
                                            type: 'bar',
                                            label: 'Offers Made',
                                            data: this.state.offers,
                                            backgroundColor: [
                                              '#2B5C91',
                                            ],
                                            borderColor: [
                                              'white',
                                            ],
                                            borderWidth: 2,
                                          },
                                        ],
                                      }}
                                      options={{
                                        indexAxis: 'y',
                                        responsive: true,
                                        scales: {
                                          scaleOverride : true,
                                          x: {
                                            min: 0,
                                            max: 50,
                                          }
                                        },
                                        plugins: {
                                          legend: {
                                            position: 'top',
                                          },
                                          title: {
                                            display: false,
                                            text: '',
                                          },
                                          datalabels: {
                                            display: true,
                                            align: 'end',
                                            anchor: 'middle',
                                            color: function(value) {
                                              if(value.dataset.data[value.dataIndex] !== 0.00) {
                                                if(value.dataset.type === 'line') {
                                                  return '#111111';
                                                } else {
                                                  return '#ffffff';
                                                }
                                              } else {
                                                if(value.dataset.type === 'line') {
                                                  return '#111111';
                                                } else {
                                                  return value.dataset.backgroundColor;
                                                }
                                              }
                                            },
                                            font: function(context) {
                                              var w = context.chart.width;
                                              return {
                                                size: w < 512 ? 11 : 11,
                                                weight: 'bold',
                                              };
                                            },
                                            formatter: function(value, context) {
                                              return Math.round(value).toString();
                                            }
                                         }
                                        },
                                      }}
                                      plugins={[ChartDataLabels]}
                                    />
                                    <p><b>Number</b></p>
                                  </div>

                                  <div className={this.state.printStatus + "ContainerSecond col-lg-12"}>
                                    <div className="title">Target Enrolment v Offers Made</div>
                                    <div className="subtitle"></div>
                                  </div>

                                  <div className={this.state.printStatus + "ContainerSecondBottom col-lg-12 mb-5"}>
                                    <Bar
                                      data={{
                                        labels: this.state.labels,
                                        datasets: [
                                          {
                                            type: 'line',
                                            label: 'Target Enrolment',
                                            data: this.state.target,
                                            backgroundColor: [
                                              '#FFBC42',
                                            ],
                                            borderColor: [
                                              '#FFBC42',
                                            ],
                                            borderWidth: 2,
                                          },
                                          {
                                            type: 'bar',
                                            label: '# of Offers Made',
                                            data: this.state.offers,
                                            backgroundColor: [
                                              '#14133d',
                                            ],
                                            borderColor: [
                                              'white',
                                            ],
                                            borderWidth: 2,
                                          },
                                        ],
                                      }}
                                      options={{
                                        indexAxis: 'y',
                                        responsive: true,
                                        scales: {
                                          scaleOverride : true,
                                          x: {
                                            min: 0,
                                            max: 50,
                                          }
                                        },
                                        plugins: {
                                          legend: {
                                            position: 'top',
                                          },
                                          title: {
                                            display: false,
                                            text: '',
                                          },
                                          datalabels: {
                                            display: true,
                                            align: 'end',
                                            anchor: 'middle',
                                            color: function(value) {
                                              if(value.dataset.data[value.dataIndex] !== 0.00) {
                                                if(value.dataset.type === 'line') {
                                                  return '#111111';
                                                } else {
                                                  return '#ffffff';
                                                }
                                              } else {
                                                if(value.dataset.type === 'line') {
                                                  return '#111111';
                                                } else {
                                                  return value.dataset.backgroundColor;
                                                }
                                              }
                                            },
                                            font: function(context) {
                                              var w = context.chart.width;
                                              return {
                                                size: w < 512 ? 11 : 11,
                                                weight: 'bold',
                                              };
                                            },
                                            formatter: function(value, context) {
                                              return Math.round(value).toString();
                                            }
                                         }
                                        },
                                      }}
                                      plugins={[ChartDataLabels]}
                                    />
                                    <p><b>Number</b></p>
                                  </div>

                                  <div className={this.state.printStatus + "ContainerSecond col-lg-12"}>
                                    <div className="title">Accepted Paid Registration v Received Application</div>
                                    <div className="subtitle"></div>
                                  </div>

                                  <div className={this.state.printStatus + "ContainerSecondBottom col-lg-12 mb-5"}>
                                    <Bar
                                      data={{
                                        labels: this.state.labels,
                                        datasets: [
                                          {
                                            type: 'line',
                                            label: 'Received Application',
                                            data: this.state.appreceived,
                                            backgroundColor: [
                                              '#FFBC42',
                                            ],
                                            borderColor: [
                                              '#FFBC42',
                                            ],
                                            borderWidth: 2,
                                          },
                                          {
                                            type: 'bar',
                                            label: 'Accepted Paid Registration',
                                            data: this.state.acceptedPaid,
                                            backgroundColor: [
                                              '#14133d',
                                            ],
                                            borderColor: [
                                              'white',
                                            ],
                                            borderWidth: 2,
                                          },
                                        ],
                                      }}
                                      options={{
                                        indexAxis: 'y',
                                        responsive: true,
                                        scales: {
                                          scaleOverride : true,
                                          x: {
                                            min: 0,
                                            max: 50,
                                          }
                                        },
                                        plugins: {
                                          legend: {
                                            position: 'top',
                                          },
                                          title: {
                                            display: false,
                                            text: '',
                                          },
                                          datalabels: {
                                            display: true,
                                            align: 'end',
                                            anchor: 'middle',
                                            color: function(value) {
                                              if(value.dataset.data[value.dataIndex] !== 0.00) {
                                                if(value.dataset.type === 'line') {
                                                  return '#111111';
                                                } else {
                                                  return '#ffffff';
                                                }
                                              } else {
                                                if(value.dataset.type === 'line') {
                                                  return '#111111';
                                                } else {
                                                  return value.dataset.backgroundColor;
                                                }
                                              }
                                            },
                                            font: function(context) {
                                              var w = context.chart.width;
                                              return {
                                                size: w < 512 ? 11 : 11,
                                                weight: 'bold',
                                              };
                                            },
                                            formatter: function(value, context) {
                                              return Math.round(value).toString();
                                            }
                                         }
                                        },
                                      }}
                                      plugins={[ChartDataLabels]}
                                    />
                                    <p><b>Number</b></p>
                                  </div>

                                  <div className={this.state.printStatus + "ContainerThird col-lg-12"}>
                                    <div className={"Container col-lg-12"}>
                                      <div className="title">Applications v Accepted / Paid Registration v Target Numbers</div>
                                      <div className="subtitle">Conversion Rate</div>
                                    </div>

                                    <div className={this.state.printStatus + "ContainerThirdBottom col-lg-12 mb-5"}>
                                      <Bar
                                        data={{
                                          labels: this.state.labels,
                                          datasets: [
                                            {
                                              type: 'bar',
                                              backgroundColor: 'rgb(243, 150, 62)',
                                              label: 'Applications v Accepted',
                                              borderColor: 'white',
                                              borderWidth: 2,
                                              data: this.state.conversionRate,
                                            },
                                            {
                                              type: 'bar',
                                              backgroundColor: '#2B5C91',
                                              label: 'Paid Registration v Target',
                                              borderColor: 'white',
                                              borderWidth: 2,
                                              data: this.state.paidRegistration,
                                            },
                                          ],
                                        }}
                                        options={{
                                          indexAxis: 'y',
                                          responsive: true,
                                          scales: {
                                            scaleOverride : true,
                                            x: {
                                              min: 0,
                                              max: 100,
                                            }
                                          },
                                          plugins: {
                                            tooltip: {
                                              callbacks: {
                                                label: function (value) {
                                                  return value.dataset.label + ': ' + value.dataset.data[value.dataIndex] + '%';
                                                }
                                              }
                                            },
                                            legend: {
                                              position: 'top',
                                            },
                                            title: {
                                              display: false,
                                              text: '',
                                            },
                                            datalabels: {
                                              display: true,
                                              align: 'end',
                                              anchor: 'middle',
                                              color: function(value) {
                                                if(value.dataset.data[value.dataIndex] !== 0.00) {
                                                  return '#ffffff';
                                                } else {
                                                  return value.dataset.backgroundColor;
                                                }
                                              },
                                              font: function(context) {
                                                var w = context.chart.width;
                                                return {
                                                  size: w < 512 ? 11 : 11,
                                                  weight: 'bold',
                                                };
                                              },
                                              formatter: function(value, context) {
                                                return Math.round(value).toString() + '%';
                                              }
                                           }
                                          },
                                        }}
                                        plugins={[ChartDataLabels]}
                                      />
                                    <p><b>Percentage</b></p>
                                    </div>
                                  </div>
                                </div>
                                :
                                <div className="weeklyReport-container">
                                  <div className={this.state.printStatus + "ContainerFirst col-lg-12"}>
                                    <div className="title">Number of Leads
                                    </div>
                                    <div className="subtitle"></div>
                                  </div>
                                   <div className={this.state.printStatus + "ContainerFirstBottom col-lg-12 mb-5"}>
                                     <div className="pie-container">
                                      <Pie
                                        data={{
                                          labels: this.state.wlapsleadsdata[0],
                                          datasets: [
                                            {
                                              type: 'pie',
                                              label: '# of Leads',
                                              data: this.state.wlapsleadsdata[1], // App Launch
                                              backgroundColor: [
                                                'rgb(220, 53, 69)',
                                                'rgb(243, 150, 62)',
                                                'rgb(13, 110, 253)',
                                              ],
                                              borderColor: [
                                                'white',
                                              ],
                                              borderWidth: 2,
                                            },
                                          ],
                                        }}
                                        options={{
                                          responsive: true,
                                          plugins: {
                                            legend: {
                                              position: 'top',
                                            },
                                            title: {
                                              display: false,
                                              text: '',
                                            },
                                            datalabels: {
                                              display: true,
                                              align: 'end',
                                              anchor: 'middle',
                                              color: function(value) {
                                                if(value.dataset.data[value.dataIndex] !== 0) { 
                                                    return '#ffffff';
                                                } else {
                                                    return value.dataset.backgroundColor;
                                                }
                                              },
                                              font: function(context) {
                                                var w = context.chart.width;
                                                return {
                                                  size: w < 512 ? 11 : 11,
                                                  weight: 'bold',
                                                };
                                              },
                                              formatter: function(value, context) {
                                                return Math.round(value).toString();
                                              }
                                           }
                                          },
                                        }}
                                        plugins={[ChartDataLabels]}
                                      />
                                    </div>
                                  </div>

                                  <div className={this.state.printStatus + "ContainerSecond col-lg-12"}>
                                    <div className="title">Individual BDE
                                    </div>
                                    <div className="subtitle"></div>
                                  </div>

                                  <div className={this.state.printStatus + "ContainerSecondBottom col-lg-12 mb-5"}>
                                    <Bar
                                      data={{
                                        labels: this.state.wlapstotaldata[0],
                                        datasets: [
                                          {
                                            type: 'bar',
                                            label: 'Applications Launched',
                                            data: this.state.wlapstotaldata[1], // App Launch
                                            backgroundColor: [
                                              'rgb(243, 150, 62)',
                                            ],
                                            borderColor: [
                                              'white',
                                            ],
                                            borderWidth: 2,
                                          },
                                          {
                                            type: 'bar',
                                            label: 'Paid Registration',
                                            data: this.state.wlapstotaldata[2], //Paid Reg
                                            backgroundColor: [
                                              '#2B5C91',
                                            ],
                                            borderColor: [
                                              'white',
                                            ],
                                            borderWidth: 2,
                                          },
                                          {
                                            type: 'line',
                                            label: 'Target',
                                            data: this.state.wlapstotaldata[3],
                                            backgroundColor: [
                                              '#FFBC42',
                                            ],
                                            borderColor: [
                                              '#FFBC42',
                                            ],
                                            borderWidth: 2,
                                          },
                                        ],
                                      }}
                                      options={{
                                        indexAxis: 'y',
                                        responsive: true,
                                        scales: {
                                          scaleOverride : true,
                                          x: {
                                            min: 0,
                                            max: 100,
                                          }
                                        },
                                        plugins: {
                                          legend: {
                                            position: 'top',
                                          },
                                          title: {
                                            display: false,
                                            text: '',
                                          },
                                          datalabels: {
                                            display: true,
                                            align: 'end',
                                            anchor: 'middle',
                                            color: function(value) {
                                              if(value.dataset.data[value.dataIndex] <= 3) {
                                                if(value.dataset.type === 'line') {
                                                  return '#111111';
                                                } else {
                                                  return '#111111';
                                                }
                                              } else if(value.dataset.data[value.dataIndex] !== 0) { 
                                                if(value.dataset.type === 'line') {
                                                  return '#111111';
                                                } else {
                                                  return '#ffffff';
                                                }
                                              } else {
                                                if(value.dataset.type === 'line') {
                                                  return '#111111';
                                                } else {
                                                  return value.dataset.backgroundColor;
                                                }
                                              }
                                            },
                                            font: function(context) {
                                              var w = context.chart.width;
                                              return {
                                                size: w < 512 ? 11 : 11,
                                                weight: 'bold',
                                              };
                                            },
                                            formatter: function(value, context) {
                                              return Math.round(value).toString();
                                            }
                                         }
                                        },
                                      }}
                                      plugins={[ChartDataLabels]}
                                    />
                                    <p><b>Number</b></p>
                                  </div>
                                </div>
                                }
                                <div className="mt-3 mb-3 text-uppercase">
                                  <p><b>Created by Artan Holding (IT Department)</b></p>
                                </div>
                              </div>
                            </div>
                          </div>

                          </>
                          }
                        </div>
                      </section>
                    </div>
              </div>
            </div>
          </div>
      </div>
    )
  }
}
 
export default Autorized

